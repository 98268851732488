<template>
	<v-main>
		<v-container>
			<v-row no-gutters>
				<v-col>
					<iframe
						:src="
							'https://app.aadalentruck.no/public/report/dagligkontroll?lang=' +
								$i18n.locale
						"
						width="100%"
						height="940px;"
						scrolling="no"
						style="border:none;"
					>
					</iframe
				></v-col>
			</v-row>
		</v-container>
	</v-main>
</template>

<script>
export default {
	data() {
		return {
			onSubmitBinding: null,
		};
	},
	methods: {
		onSubmit(event) {
			if (event.data.event !== 'submit') return;
			this.$router.push({
				path: '/',
				query: {
					success: true,
				},
			});
		},
	},
	created() {
		this.onSubmitBinding = this.onSubmit.bind(this);
		window.addEventListener('message', this.onSubmitBinding);
	},
	beforeDestroy() {
		window.removeEventListener('message', this.onSubmitBinding);
	},
};
</script>
